import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper/core'
import { getDiagnoseFacilities } from './api/common/diagnose_facilities'

export default class extends Controller {
  static targets = [
    'form',
    'step',
    'panel',
    'prefectures',
    'cities',
    'result',
    'resultNone',
    'resultList',
    'resultCarousel',
    'resultSlide',
    'resultFeeEx',
    'resultNoneFeeEx',
    'resultListFeeEx',
    'resultCarouselFeeEx',
    'resultSlideFeeEx',
    'resultAreaEx',
    'resultNoneAreaEx',
    'resultListAreaEx',
    'resultCarouselAreaEx',
    'resultSlideAreaEx',
    'facilityCheck',
    'inquiryButton',
  ]

  static classes = [
    'isVisible',
    'inactive',
    'selected',
  ]

  get className () {
    return this.element.dataset.classname
  }

  connect () {
    this.utmSource = ''
    this.utmMedium = ''
    this.utmCampaign = ''
    this.utmContent = ''

    // コラムの診断ツールからパラメータを引き継ぐ
    const url = new URL(window.location.href)
    const params = url.searchParams

    if (params.has('utm_source')) {
      this.utmSource = params.get('utm_source')
    }
    if (params.has('utm_medium')) {
      this.utmMedium = params.get('utm_medium')
    }
    if (params.has('utm_campaign')) {
      this.utmCampaign = params.get('utm_campaign')
    }
    if (params.has('utm_content')) {
      this.utmContent = params.get('utm_content')
    }
  }

  submitData () {
    let params = {}
    let monthlyFeeMin = null
    let monthlyFeeMax = null
    const specialFeatures = []

    if (this.formTarget.elements.newopen.value === '5') {
      specialFeatures.push('newopen')
    }

    if (this.formTarget.elements.premium.value === '5') {
      specialFeatures.push('premium')
    }

    if (this.formTarget.elements.no_admission_fee.value === '5') {
      specialFeatures.push('no-admission-fee')
    }

    if (this.formTarget.elements.seikatuhogo.value === '5') {
      specialFeatures.push('seikatuhogo')
    }

    if (this.formTarget.elements.terminalcare.value === '5') {
      specialFeatures.push('terminalcare')
    }

    switch (this.formTarget.elements.monthly_fee.value) {
      case '1':
        monthlyFeeMin = 120000
        monthlyFeeMax = 240000
        break
      case '2':
        monthlyFeeMin = 0
        monthlyFeeMax = 119999
        break
      case '3':
        monthlyFeeMin = 240000
        monthlyFeeMax = null
        break
      default:
        monthlyFeeMin = null
        monthlyFeeMax = null
        break
    }

    params = {
      monthly_fee_min: monthlyFeeMin,
      monthly_fee_max: monthlyFeeMax,
      prefecture_id: this.formTarget.elements.prefecture_id.value,
      city_ids: this.formTarget.elements.city_id.value ? [this.formTarget.elements.city_id.value] : null,
      special_features: specialFeatures,
    }

    getDiagnoseFacilities(params)
      .then((data) => {
        console.log('submit success')
        if (data.results.length > 0) {
          // 診断結果が1件以上の場合、結果画面を表示
          this.resultTarget.classList.add(this.isVisibleClass)
          this.resultNoneTarget.classList.remove(this.isVisibleClass)
          this.appendList(data.results, this.resultListTarget)
          this.appendCarousel(data.results, this.resultCarouselTarget, this.resultSlideTarget)
        } else {
          // 診断結果が0の場合、条件を緩和して再診断
          this.resultTarget.classList.remove(this.isVisibleClass)
          this.resultNoneTarget.classList.add(this.isVisibleClass)
          this.inquiryButtonTargets.forEach((target) => {
            target.classList.add(`${this.className}::disabled`)
            target.href = ''
          })
          this.resultListTarget.innerHTML = ''
          this.resultSlideTarget.innerHTML = ''

          // 予算をひろげて再診断
          const feeExParams = Object.assign(params)
          delete feeExParams.monthly_fee_min
          delete feeExParams.monthly_fee_max

          getDiagnoseFacilities(feeExParams)
            .then((data) => {
              console.log('fee expansion success')
              if (data.results.length > 0) {
                this.resultNoneFeeExTarget.classList.remove(this.isVisibleClass)
                this.resultFeeExTarget.classList.add(this.isVisibleClass)
                this.appendList(data.results, this.resultListFeeExTarget)
                this.appendCarousel(data.results, this.resultCarouselFeeExTarget, this.resultSlideFeeExTarget)
              } else {
                this.resultNoneFeeExTarget.classList.add(this.isVisibleClass)
                this.resultFeeExTarget.classList.remove(this.isVisibleClass)
                this.inquiryButtonTargets.forEach((target) => {
                  target.classList.add(`${this.className}::disabled`)
                  target.href = ''
                })
                this.resultListFeeExTarget.innerHTML = ''
                this.resultSlideFeeExTarget.innerHTML = ''
              }
            })

          // エリアをひろげて再診断
          const areaExParams = Object.assign(params)
          delete areaExParams.city_ids

          getDiagnoseFacilities(areaExParams)
            .then((data) => {
              console.log('area expansion success')
              if (data.results.length > 0) {
                this.resultNoneAreaExTarget.classList.remove(this.isVisibleClass)
                this.resultAreaExTarget.classList.add(this.isVisibleClass)
                this.appendList(data.results, this.resultListAreaExTarget)
                this.appendCarousel(data.results, this.resultCarouselAreaExTarget, this.resultSlideAreaExTarget)
              } else {
                this.resultNoneAreaExTarget.classList.add(this.isVisibleClass)
                this.resultAreaExTarget.classList.remove(this.isVisibleClass)
                this.inquiryButtonTargets.forEach((target) => {
                  target.classList.add(`${this.className}::disabled`)
                  target.href = ''
                })
                this.resultListAreaExTarget.innerHTML = ''
                this.resultSlideAreaExTarget.innerHTML = ''
              }
            })
        }
      })
  }

  fee (value) {
    return Math.round(value / 10000)
  }

  changeSelect (e) {
    if (e.target.value) {
      e.target.parentElement.classList.add(this.selectedClass)
    } else {
      e.target.parentElement.classList.remove(this.selectedClass)
    }
  }

  changePrefecture (prefectureId) {
    if (prefectureId) {
      fetch(`/area/prefectures/${prefectureId}/cities.json`)
        .then(response => response.json())
        .then(cities => this.updateCities(cities))
    }
    this.citiesTarget.value = ''
  }

  updateCities (cities) {
    this.citiesTarget.innerHTML =
      '<option value="" selected="">市区町村を選択</option>' +
      cities.map((city) => (
        `<option value="${city.id}">${city.name}</option>`
      )).join('')
  }

  setStep (target) {
    this.stepTargets.forEach((elm, index) => {
      if (index <= target - 1) {
        elm.classList.remove(this.inactiveClass)
      } else {
        elm.classList.add(this.inactiveClass)
      }
    })
  }

  prev (e) {
    const current = Number(e.target.dataset.current)
    this.panelTargets[current - 2].classList.add(this.isVisibleClass)
    this.panelTargets[current - 1].classList.remove(this.isVisibleClass)
    console.log(`prev:${current - 1}`)
  }

  next (e) {
    const current = Number(e.target.dataset.current)
    this.panelTargets[current].classList.add(this.isVisibleClass)
    this.panelTargets[current - 1].classList.remove(this.isVisibleClass)
    console.log(`next:${current + 1}`)
  }

  reset () {
    this.setStep(1)
    this.panelTargets.forEach(elm => {
      elm.classList.remove(this.isVisibleClass)
    })
    this.panelTargets[0].classList.add(this.isVisibleClass)
    this.prefecturesTarget.value = ''
    this.prefecturesTarget.parentElement.classList.remove(this.selectedClass)
    this.citiesTarget.value = ''
    this.citiesTarget.parentElement.classList.remove(this.selectedClass)
    this.citiesTarget.innerHTML = '<option value="" selected="">市区町村を選択</option>'
    this.formTarget.elements.monthly_fee.forEach(elm => {
      elm.checked = false
    })
    this.formTarget.elements.occupant_timing_id.forEach(elm => {
      elm.checked = false
    })
    this.formTarget.elements.newopen.forEach(elm => {
      elm.checked = false
    })
    this.formTarget.elements.premium.forEach(elm => {
      elm.checked = false
    })
    this.formTarget.elements.no_admission_fee.forEach(elm => {
      elm.checked = false
    })
    this.formTarget.elements.seikatuhogo.forEach(elm => {
      elm.checked = false
    })
    this.formTarget.elements.terminalcare.forEach(elm => {
      elm.checked = false
    })
    this.formTarget.elements.recreation.forEach(elm => {
      elm.checked = false
    })
    this.formTarget.elements.dementia.forEach(elm => {
      elm.checked = false
    })
    this.formTarget.elements.necessary_medical_treatments.forEach(elm => {
      elm.checked = false
    })
    this.formTarget.elements.necessary_medical_treatments_detail.forEach(elm => {
      elm.checked = false
    })
    this.formTarget.elements.social_security_status.forEach(elm => {
      elm.checked = false
    })
    this.formTarget.elements.guarantor.forEach(elm => {
      elm.checked = false
    })
    this.formTarget.elements.occupant_care_level.forEach(elm => {
      elm.checked = false
    })
    this.resultTarget.classList.remove(this.isVisibleClass)
    this.resultNoneTarget.classList.remove(this.isVisibleClass)
    this.resultFeeExTarget.classList.remove(this.isVisibleClass)
    this.resultNoneFeeExTarget.classList.remove(this.isVisibleClass)
    this.resultAreaExTarget.classList.remove(this.isVisibleClass)
    this.resultNoneAreaExTarget.classList.remove(this.isVisibleClass)
  }

  step1a (e) {
    const value = this.prefecturesTarget.value

    if (value !== '') {
      this.changePrefecture(value)
    }
  }

  step1b (e) {
    const value = this.citiesTarget.value

    if (value !== '') {
      this.next(e)
    }
  }

  step2 (e) {
    const value = this.formTarget.elements.monthly_fee.value

    if (value !== '') {
      this.next(e)
    }
  }

  step3 (e) {
    const value = this.formTarget.elements.occupant_timing_id.value

    if (value !== '') {
      this.next(e)
      this.setStep(2)
    }
  }

  step4 (e) {
    const value = this.formTarget.elements.newopen.value

    if (value !== '') {
      this.next(e)
    }
  }

  step5 (e) {
    const value = this.formTarget.elements.premium.value

    if (value !== '') {
      this.next(e)
    }
  }

  step6 (e) {
    const value = this.formTarget.elements.no_admission_fee.value

    if (value !== '') {
      this.next(e)
    }
  }

  step7 (e) {
    const value = this.formTarget.elements.seikatuhogo.value

    if (value !== '') {
      this.next(e)
    }
  }

  step8 (e) {
    const value = this.formTarget.elements.terminalcare.value

    if (value !== '') {
      this.next(e)
    }
  }

  step9 (e) {
    const value = this.formTarget.elements.recreation.value

    if (value !== '') {
      this.next(e)
      this.setStep(3)
    }
  }

  step10 (e) {
    const value = this.formTarget.elements.dementia.value

    if (value !== '') {
      this.next(e)
    }
  }

  step11 (e) {
    const value = this.formTarget.elements.necessary_medical_treatments.value

    if (value !== '') {
      this.next(e)
    }
  }

  step12 (e) {
    this.next(e)
  }

  step13 (e) {
    const value = this.formTarget.elements.social_security_status.value

    if (value !== '') {
      this.next(e)
    }
  }

  step14 (e) {
    const value = this.formTarget.elements.guarantor.value

    if (value !== '') {
      this.next(e)
    }
  }

  submit (e) {
    this.submitData()
    this.next(e)
  }

  check (e) {
    const ids = []
    this.facilityCheckTargets.forEach((target) => {
      if (target.checked === true) {
        ids.push(target.value)
      }
    })
    this.appendLink(ids)
  }

  toInquiry () {
  }

  appendLink (facilityIds) {
    if (facilityIds.length < 1) {
      this.inquiryButtonTargets.forEach((target) => {
        target.classList.add(`${this.className}::disabled`)
        target.href = ''
      })

      return
    }

    let url = `/diagnose/new/?category_id=inquiry&inquiry_type=offer&utm_source=${this.utmSource}&utm_medium=${this.utmMedium}&utm_campaign=${this.utmCampaign}&utm_content=${this.utmContent}`

    facilityIds.forEach((id) => {
      url += `&facility_ids[]=${id}`
    })

    this.inquiryButtonTargets.forEach((target) => {
      target.classList.remove(`${this.className}::disabled`)
      target.href = url
    })
  }

  appendList (results, target) {
    target.innerHTML =
      results.map((result) => (
        `<li><label class="${this.className}::checkbox"><input type="checkbox" id="facility_ids" name="facility_ids" value="${result.facility_id}" data-site--common--diagnose--component-target="facilityCheck" data-action="click->site--common--diagnose--component#check"><i></i></label>${result.facility_name}</li>`
      )).join('')
  }

  appendCarousel (results, carouselTarget, slideTarget) {
    slideTarget.innerHTML = ''

    results.forEach(result => {
      let admissionFee = ''
      let monthlyFee = ''
      let cost = ''
      let photo = ''
      let reviews = ''
      let vacancies = ''
      let info = ''
      const stations = []
      const labels = []

      if (result.low_admission_fee_total != null && result.high_admission_fee_total != null) {
        if (result.low_admission_fee_total === result.high_admission_fee_total) {
          admissionFee = `
            <div class="${this.className}::diagnose-form-slide__cost-fee">
              <span class="${this.className}::diagnose-form-slide__cost-heading">入居金</span>
              <span class="${this.className}::diagnose-form-slide__cost-value"><em>${this.fee(result.low_admission_fee_total)}</em>万円</span>
            </div>`
        } else {
          admissionFee = `
            <div class="${this.className}::diagnose-form-slide__cost-fee">
              <span class="${this.className}::diagnose-form-slide__cost-heading">入居金</span>
              <span class="${this.className}::diagnose-form-slide__cost-value"><em>${this.fee(result.low_admission_fee_total)}</em><span>〜</span><em>${this.fee(result.high_admission_fee_total)}</em>万円</span>
            </div>`
        }
      }

      if (result.low_monthly_fee_total != null && result.high_monthly_fee_total != null) {
        if (result.low_monthly_fee_total === result.high_monthly_fee_total) {
          monthlyFee = `
            <div class="${this.className}::diagnose-form-slide__cost-fee">
              <span class="${this.className}::diagnose-form-slide__cost-heading">月額</span>
              <span class="${this.className}::diagnose-form-slide__cost-value"><em>${this.fee(result.low_monthly_fee_total)}</em>万円</span>
            </div>`
        } else {
          monthlyFee = `
            <div class="${this.className}::diagnose-form-slide__cost-fee">
              <span class="${this.className}::diagnose-form-slide__cost-heading">月額</span>
              <span class="${this.className}::diagnose-form-slide__cost-value"><em>${this.fee(result.low_monthly_fee_total)}</em><span>〜</span><em>${this.fee(result.high_monthly_fee_total)}</em>万円</span>
            </div>`
        }
      }

      if (admissionFee || monthlyFee) {
        cost = `<div class="${this.className}::diagnose-form-slide__cost">${admissionFee + monthlyFee}</div>`
      }

      if (result.facility_image_url) {
        photo = `
          <a class="${this.className}::diagnose-form-slide__photo" href="${result.facility_url}" target="_blank" rel="noopener noreferrer">
            <img loading="lazy" alt="" width="176" height="176" src="${result.facility_image_url}">
          </a>`
      }

      if (result.reviews_count != null) {
        reviews = `<dl class="${this.className}::diagnose-form-slide__reviews"><dt>口コミ</dt><dd>口コミ<em>${result.reviews_count}</em>件</dd></dl>`
      }

      if (result.is_vacancy_displayable && result.vacancies_total != null) {
        vacancies = `<dl class="${this.className}::diagnose-form-slide__vacancies"><dt>空室</dt><dd>${result.vacancies_total}</dd></dl>`
      }

      if (photo || cost || (stations.length > 0)) {
        info = `
          <div class="${this.className}::diagnose-form-slide__info">
            ${photo}
            <div class="${this.className}::diagnose-form-slide__info-text">
              ${cost}
              <div class="${this.className}::diagnose-form-slide__stations">${stations.join('')}</div>
            </div>
          </div>`
      }

      result.near_stations.forEach(station => {
        stations.push(`<div class="${this.className}::diagnose-form-slide__station"><a href="${station.station_url}" target="_blank" rel="noopener noreferrer">${station.station_name}</a>から 徒歩${station.walking_time}分</div>`)
      })

      result.supported_care_level_labels.forEach(label => {
        const className = label.existence ? `${this.className}::diagnose-form-slide__label--exist` : ''
        labels.push(`<li class="${this.className}::diagnose-form-slide__label ${className}">${label.name}</li>`)
      })

      const content = `
        <div class="${this.className}::diagnose-form-slide swiper-slide">
          <div class="${this.className}::diagnose-form-slide__container">
            <div class="${this.className}::diagnose-form-slide__heading">
              <a href="${result.facility_url}" target="_blank" rel="noopener noreferrer">${result.facility_name}</a>
            </div>
            <div class="${this.className}::diagnose-form-slide__content">
              <div class="${this.className}::diagnose-form-slide__reviews-and-vacancies">${reviews + vacancies}</div>
              ${info}
            </div>
            <ul class="${this.className}::diagnose-form-slide__labels">${labels.join('')}</ul>
          </div>
        </div>
      `

      slideTarget.insertAdjacentHTML('beforeend', content)
    })

    const swiper = new Swiper(carouselTarget, {
      loop: false,
      centeredSlides: true,
      slidesPerView: 'auto',
      spaceBetween: 12,
      navigation: {
        prevEl: '#carousel_prev',
        nextEl: '#carousel_next',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        bulletClass: `${this.className}::bullet`,
        bulletActiveClass: `${this.className}::bullet-active`,
      },
    })

    swiper.enable()
  }
}
